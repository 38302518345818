import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { User, UsersService, SortDirectionEnum } from "@hydrantid/acm-client";
import { Store } from "@ngrx/store";
import { AppUser, AppUserSelectors } from "../../../shared/app-user";
import { combineLatest, Observable } from "rxjs";
import { tap } from "rxjs/operators";

interface ViewState {
    email: string;
    expiryEmailCount: number;
    certificatesCount: number;
    users: User[];
    typeAheadEmail: string;
}

@Injectable()
export class ReassignViewStore extends ComponentStore<ViewState> {
    constructor(
        protected globalStore: Store<{ appUser: AppUser }>,
        public usersService: UsersService,
    ) {
        super({
            email: "",
            expiryEmailCount: 0,
            certificatesCount: 0,
            users: [],
            typeAheadEmail: "",
        });
    }

    readonly setEmail = this.updater((state, email: string) => ({ ...state, email }));
    readonly setTypeaheadEmail = this.updater((state, typeAheadEmail: string) => ({ ...state, typeAheadEmail }));

    readonly setExpiryEmailCount = this.updater((state, expiryEmailCount: number) => ({
        ...state,
        expiryEmailCount,
    }));
    readonly setCertificatesCount = this.updater((state, certificatesCount: number) => ({
        ...state,
        certificatesCount,
    }));
    readonly setUsers = this.updater((state, users: User[]) => ({ ...state, users }));

    readonly setUser = this.effect((user$: Observable<User>) => {
        return user$.pipe(
            tap({
                next: (user) => {
                    combineLatest([
                        this.usersService.usersIdExpiryEmailCountGet(user.id),
                        this.globalStore.select(AppUserSelectors.user),
                    ]).subscribe(([result, appUser]) => {
                        this.setExpiryEmailCount(result.expiryEmails ?? 0);
                        this.setCertificatesCount(result.certificates ?? 0);
                        this.setEmail(result.email ?? "");

                        this.usersService
                            .usersPost({
                                account: !!appUser && appUser.systemRoles.admin ? undefined : result.accountId,
                                nolimit: true,
                                offset: 0,
                                sort_type: "email",
                                sort_direction: SortDirectionEnum.ASC,
                            })
                            .subscribe((results) => {
                                if (results.users) {
                                    this.setUsers(results.users as User[]);
                                }
                            });
                    });
                },
            }),
        );
    });

    // only users that have email, Service requestor don't have it
    readonly users$ = this.select((state) => state.users.filter((user) => user.email));
    readonly email$ = this.select((state) => state.email);
    readonly expiryEmailCount$ = this.select((state) => state.expiryEmailCount);
    readonly certificatesCount$ = this.select((state) => state.certificatesCount);
    readonly typeAheadEmail$ = this.select((state) => state.typeAheadEmail);

    readonly vm$ = this.select(
        this.email$,
        this.expiryEmailCount$,
        this.certificatesCount$,
        (email, expiryEmailCount, certificatesCount) => ({
            email,
            expiryEmailCount,
            certificatesCount,
        }),
    );

    readonly filteredUsers$: Observable<User[]> = this.select(
        this.users$,
        this.typeAheadEmail$,
        (users, filterValue) => {
            if (!filterValue) {
                return users;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            return users.filter((user) => user.email.toLocaleLowerCase().indexOf(lowerFilterValue) >= 0);
        },
    );
}
