import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { AppUser, AppUserActions, AppUserSelectors } from "../../../shared/app-user";
import { combineLatest, Subject } from "rxjs";
import { Permission, ViewStore } from "./view-state";
import { IFormGroup } from "@rxweb/types";
import { MatDrawer } from "@angular/material/sidenav";
import { DatePipe, Location } from "@angular/common";
import { LogFilters } from "../../../shared/logs/components/list/log-view-state";
import { LogsListComponent } from "../../../shared/logs/components/list/list.component";
import { IconsService } from "../../../../services/icons/icons.service";
import { AccountRoles, HawkCredential, OrganizationRoles, User } from "@hydrantid/acm-client";
import { UserManagementService } from "../../../../services/user-management/user-management.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogManageComponent } from "../dialog-manage/dialog-manage.component";
import { MessageService } from "../../../../services/message/message.service";
import { DialogReassignEmailComponent } from "../dialog-reassign-email/dialog-reassign-email.component";
import { DialogAddPermissionComponent } from "../dialog-add-permission/dialog-add-permission.component";
import { DialogApiKeyComponent, DialogApiKeyData } from "../dialog-api-key/dialog-api-key.component";
import { ApiKeyMode } from "../dialog-api-key/view-store";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { first, take, takeUntil } from "rxjs/operators";
import { DialogsService } from "../../../shared/dialogs";
import { AccountFilterStore } from "../../../../shared-models/account-filter-state";
import { FormControl } from "@angular/forms";
import { DialogLogSubscriptionsComponent } from "../dialog-log-subscriptions/dialog-log-subscriptions.component";
import { SystemRoles } from "@hydrantid/acm-client/model/systemRoles";

@Component({
    selector: "app-view",
    templateUrl: "./view.component.html",
    styleUrls: ["./view.component.scss"],
    providers: [ViewStore, AccountFilterStore],
})
export class ViewComponent implements AfterViewInit {
    readonly logsDisplayedColumns = ["type", "description", "createdAt"];
    readonly keysDisplayedColumns = ["id", "createdAt", "lastUsed", "comments"];
    readonly permissionsDisplayedColumns = ["accountName", "organizationName", "roleName", "action"];
    filterForm: IFormGroup<LogFilters> | undefined;
    apiKeysDataSource: MatTableDataSource<HawkCredential> = new MatTableDataSource<HawkCredential>();
    permissionsDataSource: MatTableDataSource<Permission> = new MatTableDataSource<Permission>();
    eventsExpanded = false;
    logsLoaded = false;
    i = 0;
    showBackButton = false;
    private unsubscribe = new Subject<void>();

    constructor(
        public viewStore: ViewStore,
        public datePipe: DatePipe,
        public icons: IconsService,
        private activatedRoute: ActivatedRoute,
        private globalStore: Store<{ appUser: AppUser }>,
        private changeDetectionRef: ChangeDetectorRef,
        private userManagementService: UserManagementService,
        private dialog: MatDialog,
        private messageService: MessageService,
        private dialogsService: DialogsService,
        private location: Location,
        public accountFilterState: AccountFilterStore,
    ) {}

    @ViewChild("filterDrawer") filterDrawer: MatDrawer | undefined;
    @ViewChild("logs") logs: LogsListComponent | undefined;
    @ViewChild("apiKeysTableSort") apiKeysTableSort: MatSort | undefined;
    @ViewChild("permissionsTableSort") permissionsTableSort: MatSort | undefined;

    setFilterForm(filterForm: IFormGroup<LogFilters>): void {
        this.filterForm = filterForm;
        this.changeDetectionRef.detectChanges();
        this.accountFilterState.bindSelectionControls(
            this.filterForm.controls.account_id as FormControl,
            this.filterForm.controls.organization_id as FormControl,
        );
    }

    ngAfterViewInit(): void {
        this.viewStore.hawkCredentials$.subscribe((credentials) => {
            if (credentials.length > this.i) {
                this.i += 1;
            }
            this.apiKeysDataSource.data = credentials;
        });
        combineLatest([this.activatedRoute.params, this.globalStore.select(AppUserSelectors.user)]).subscribe(
            ([params, appUser]) => {
                if ("uuid" in params) {
                    this.viewStore.setProfileUUID(params.uuid);
                    this.filterForm?.controls.user_id?.setValue(params.uuid);
                    this.showBackButton = true;
                } else if (appUser) {
                    this.viewStore.setProfileUUID(appUser.id);
                    this.filterForm?.controls.user_id?.setValue(appUser.id);
                    this.showBackButton = false;
                }
                this.changeDetectionRef.detectChanges();
            },
        );
        this.viewStore.permissions$.pipe(takeUntil(this.unsubscribe)).subscribe((permissions) => {
            this.permissionsDataSource.data = permissions;
        });
        // @ts-ignore
        this.apiKeysDataSource.sort = this.apiKeysTableSort;
        // @ts-ignore
        this.permissionsDataSource.sort = this.permissionsTableSort;
    }

    resetPassword(user: User | undefined): void {
        this.userManagementService.resetPassword(user);
    }

    setBlocked(user: User | undefined, blocked: boolean): void {
        this.userManagementService.setBlocked(user, blocked).subscribe(
            (result) => {
                if (result) {
                    this.viewStore.setBlocked(blocked);
                }
            },
            (error) => this.messageService.showError(error.error),
        );
    }

    handleApiKeyResponse(response: DialogApiKeyData, previous: HawkCredential): void {
        //This should really happen in state, but the darn apiKeysDataSource using the observer just wasn't getting called
        if (response.mode === ApiKeyMode.ROLL || response.mode === ApiKeyMode.EDIT) {
            this.viewStore.hawkCredentials$.pipe(first()).subscribe((hawkCredentials) => {
                const index = hawkCredentials.indexOf(previous);
                if (index < 0) {
                    //couldn't find it, so add one
                    this.viewStore.addHawkCredential(response.credential);
                    return;
                }
                hawkCredentials.splice(index, 1, response.credential);
                this.viewStore.setHawkCredentials([...hawkCredentials]);
            });
            if (response.mode === ApiKeyMode.ROLL) {
                this.showNewApiKey(response.userId, response.credential);
            }
        } else if (response.mode === ApiKeyMode.DELETE) {
            this.viewStore.hawkCredentials$.pipe(first()).subscribe((hawkCredentials) => {
                const index = hawkCredentials.indexOf(previous);
                if (index >= 0) {
                    hawkCredentials.splice(index, 1);
                    this.viewStore.setHawkCredentials([...hawkCredentials]);
                }
            });
        }
    }

    manageApiKey(user: User | undefined, credential: HawkCredential): void {
        if (user) {
            const data: DialogApiKeyData = {
                credential: credential,
                mode: ApiKeyMode.EDIT,
                userId: user.id,
            };
            this.dialog
                .open(DialogApiKeyComponent, {
                    width: "600px",
                    data,
                    autoFocus: false,
                })
                .afterClosed()
                .subscribe((result: DialogApiKeyData | null) => {
                    if (result) {
                        this.handleApiKeyResponse(result, credential);
                    }
                });
        }
    }

    showNewApiKey(userId: string, credential: HawkCredential): void {
        const data: DialogApiKeyData = {
            credential,
            userId,
            mode: ApiKeyMode.NEW,
        };
        this.dialog.open(DialogApiKeyComponent, {
            width: "600px",
            data,
            autoFocus: false,
        });
    }

    addApiKey(user: User | undefined): void {
        if (user) {
            this.viewStore.usersService.usersHawkUserIdPost(user.id).subscribe(
                (result) => {
                    this.viewStore.addHawkCredential(result);
                    this.showNewApiKey(user.id, result);
                },
                (error) => this.messageService.showError(error.error),
            );
        }
    }

    addPermission(user: User | undefined): void {
        if (!user) {
            return;
        }
        this.viewStore.serviceRequestor$.pipe(first()).subscribe((serviceRequestor) => {
            this.dialog
                .open(DialogAddPermissionComponent, {
                    width: "600px",
                    data: { user, serviceRequestor },
                    autoFocus: false,
                })
                .afterClosed()
                .subscribe((result: Record<string, SystemRoles | AccountRoles[] | OrganizationRoles[]> | null) => {
                    if (result) {
                        this.viewStore.setPermissionsFromRoles(result);
                    }
                });
        });
    }

    reassignEmails(user: User | undefined): void {
        if (!user) {
            return;
        }
        this.dialog.open(DialogReassignEmailComponent, {
            width: "600px",
            data: user,
        });
    }

    manageName(user: User | undefined): void {
        if (!user) {
            return;
        }
        this.dialog
            .open(DialogManageComponent, {
                width: "600px",
                data: user,
            })
            .afterClosed()
            .subscribe((dialogResult: { firstName: string; lastName: string } | null) => {
                if (!dialogResult) {
                    return;
                }
                this.viewStore.usersService
                    .usersUpdatePost({
                        id: user.id,
                        first_name: dialogResult.firstName,
                        last_name: dialogResult.lastName,
                    })
                    .subscribe(
                        (result) => {
                            if (this.messageService.showResult(result)) {
                                this.viewStore.setUser(result.user);
                                this.globalStore
                                    .select(AppUserSelectors.user)
                                    .pipe(take(1))
                                    .subscribe((appUser) => {
                                        if (appUser?.id === user.id) {
                                            this.globalStore.dispatch(
                                                AppUserActions.setUser({
                                                    appUser: {
                                                        ...appUser,
                                                        ...dialogResult,
                                                    },
                                                }),
                                            );
                                        }
                                    });
                            }
                        },
                        (error) => this.messageService.showError(error.error),
                    );
            });
    }

    resetMFA(user: User | undefined): void {
        if (!user) {
            return;
        }
        this.dialogsService
            .confirm(
                "Resetting multifactor authentication will require the user to add MFA to their account on their next login.",
                "Would you like to reset multifactor authentication?",
                "Reset",
                undefined,
                600,
            )
            .subscribe((result) => {
                if (result) {
                    this.viewStore.usersService.usersResetMfaPost({ id: user.id, reset_mfa: true }).subscribe(
                        (result) => this.messageService.showResult(result),
                        (error) => this.messageService.showError(error.error),
                    );
                }
            });
    }

    editSubscriptions(user: User | undefined): void {
        if (!user) {
            return;
        }
        this.viewStore.notificationSubscriptions$.pipe(first()).subscribe((subscriptions) => {
            this.dialog
                .open(DialogLogSubscriptionsComponent, {
                    width: "600px",
                    data: {
                        userId: user.id,
                        subscriptions,
                    },
                })
                .afterClosed()
                .subscribe((result: string[] | undefined) => {
                    if (result) {
                        this.viewStore.setNotificationSubscriptions(result);
                    }
                });
        });
    }

    toggleEventsExpanded(profileUuid: string): void {
        this.eventsExpanded = !this.eventsExpanded;
        if (this.eventsExpanded && !this.logsLoaded) {
            /* istanbul ignore next */
            this.filterForm?.controls.user_id?.setValue(profileUuid);
            this.logsLoaded = true;
            this.logs?.setFilters();
        }
    }

    goBackToUsers(): void {
        this.location.back();
    }
}
