import { Injectable } from "@angular/core";
import {
    faEllipsisV,
    faSitemap,
    faCheck as solidCheck,
    faPencil as pencilSolid,
    faQuestion,
    faBadgeCheck,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faBars,
    faSpinner,
    faTimes,
    faChartBar,
    faChartLine,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faPlus,
    faDownload as downloadRegular,
    faPencil as pencilRegular,
    faClipboard,
    faSync,
    faUpload,
    faCommentAlt,
    faFileCertificate,
    faLock,
    faUserCircle,
    faDownload,
    faCog,
    faFileCode,
    faEye,
    faEyeSlash,
    faFileImport,
    faRadar,
    faScrewdriverWrench,
    faRectangleHistory,
    faFileZip,
    faFileLines,
    faSlashForward,
    faStamp,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faTachometerAlt,
    faList,
    faListAlt,
    faClipboardList,
    faUsers,
    faFileSpreadsheet,
    faCheck,
    faFile,
    faFilePdf,
    faVideo,
    faExpandAlt,
    faCompressAlt,
    faSearch,
    faUserCircle as faUserCircleLight,
    faAngleRight,
    faPencil,
    faTrashAlt,
    faGlobe,
    faExclamationCircle,
    faFileMagnifyingGlass,
    faFileImport as faFileImportLight,
} from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

@Injectable({
    providedIn: "root",
})

// Use the FontAwesome class names, dropping the "fa".
// Multiple styles of the same icon should carry the style suffix on the friendly name: timesLight, timesRegular, timesSolid
export class IconsService {
    spinner: IconDefinition = faSpinner;
    ellipsisV: IconDefinition = faEllipsisV;
    bars: IconDefinition = faBars;
    userCircle: IconDefinition = faUserCircle;
    userCircleLight: IconDefinition = faUserCircleLight;
    times: IconDefinition = faTimes;
    tachometerAlt: IconDefinition = faTachometerAlt;
    list: IconDefinition = faList;
    listAlt: IconDefinition = faListAlt;
    fileCertificate: IconDefinition = faFileCertificate;
    upload: IconDefinition = faUpload;
    commentAlt: IconDefinition = faCommentAlt;
    lock: IconDefinition = faLock;
    clipboardList: IconDefinition = faClipboardList;
    users: IconDefinition = faUsers;
    question: IconDefinition = faQuestion;
    downloadLogs: IconDefinition = faFileSpreadsheet;
    download: IconDefinition = faDownload;
    sitemap: IconDefinition = faSitemap;
    check: IconDefinition = faCheck;
    solidCheck: IconDefinition = solidCheck;
    file: IconDefinition = faFile;
    filePdf: IconDefinition = faFilePdf;
    video: IconDefinition = faVideo;
    barChart: IconDefinition = faChartBar;
    lineChart: IconDefinition = faChartLine;
    expand: IconDefinition = faExpandAlt;
    compress: IconDefinition = faCompressAlt;
    search: IconDefinition = faSearch;
    angleRight: IconDefinition = faAngleRight;
    trash: IconDefinition = faTrashAlt;
    pencil: IconDefinition = faPencil;
    pencilRegular: IconDefinition = pencilRegular;
    pencilSolid: IconDefinition = pencilSolid;
    chevronLeft: IconDefinition = faChevronLeft;
    chevronDown: IconDefinition = faChevronDown;
    chevronUp: IconDefinition = faChevronUp;
    globe: IconDefinition = faGlobe;
    exclamationCircle: IconDefinition = faExclamationCircle;
    copy: IconDefinition = faClipboard;
    plus: IconDefinition = faPlus;
    gear: IconDefinition = faCog;
    downloadRegular: IconDefinition = downloadRegular;
    refresh: IconDefinition = faSync;
    accountAdmin: IconDefinition = faFileCode;
    show: IconDefinition = faEye;
    hide: IconDefinition = faEyeSlash;
    fileImport: IconDefinition = faFileImport;
    radar: IconDefinition = faRadar;
    tools: IconDefinition = faScrewdriverWrench;
    history: IconDefinition = faRectangleHistory;
    discovered: IconDefinition = faFileMagnifyingGlass;
    imported: IconDefinition = faFileImportLight;
    fileZip: IconDefinition = faFileZip;
    fileLines: IconDefinition = faFileLines;
    forwardSlash: IconDefinition = faSlashForward;
    badgeCheck: IconDefinition = faBadgeCheck;
    report: IconDefinition = faChartLine;
    selfSigned: IconDefinition = faStamp;
}
