import { ComponentStore } from "@ngrx/component-store";
import {
    User,
    UsersService,
    UserProfileResponseAuth0,
    HawkCredential,
    RolesService,
    OrganizationRoles,
    AccountRoles,
    AccountConfigurationAuth0Minimal,
} from "@hydrantid/acm-client";
import { Store } from "@ngrx/store";
import { AppUser, AppUserSelectors, AppUserUtility } from "../../../shared/app-user";
import { first, take, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { SystemRoles } from "@hydrantid/acm-client/model/systemRoles";
import { DialogsService } from "../../../shared/dialogs";
import { FlattenedRoles } from "../../../shared/app-user/models/app-user";
import { CachedDataService } from "../../../../services/cached-data/cached-data.service";
import { environment } from "../../../../../configuration";
import { PrimaryRole } from "../../../shared/app-user/models/primary-roles";

export interface Permission {
    organizationId?: string | undefined;
    organizationName?: string | undefined;
    accountId?: string | undefined;
    accountName?: string;
    roleName: string;
}

interface ViewState {
    profileUuid: string | undefined;
    user: User | undefined;
    auth0User: UserProfileResponseAuth0 | undefined;
    hawkCredentials: HawkCredential[];
    permissions: Permission[];
    notificationSubscriptions: string[];
    errorMessage: string | undefined;
    connectionInfo: AccountConfigurationAuth0Minimal | undefined;
}

interface PagePermissions {
    apiKeysView: boolean;
    apiKeysManage: boolean;
    block: boolean;
    editName: boolean;
    mfaReset: boolean;
    reassignNotificationEmail: boolean;
    resetPassword: boolean;
    rolesView: boolean;
    rolesAdd: boolean;
    subscriptionsView: boolean;
    subscriptionsManage: boolean;
}

export interface ViewModel {
    user: User | undefined;
    auth0User: UserProfileResponseAuth0 | undefined;
    pagePermissions: PagePermissions;
    errorMessage: string | undefined;
    connectionInfo: AccountConfigurationAuth0Minimal | undefined;
    allowMoreCredentials: boolean;
}

@Injectable()
export class ViewStore extends ComponentStore<ViewState> {
    totalAllowedCredentials = environment.totalAllowedCredentials || 10;
    constructor(
        protected globalStore: Store<{ appUser: AppUser }>,
        public usersService: UsersService,
        private cachedDataService: CachedDataService,
        private dialogsService: DialogsService,
        private rolesService: RolesService,
    ) {
        super({
            profileUuid: undefined,
            user: undefined,
            auth0User: undefined,
            hawkCredentials: [],
            permissions: [],
            notificationSubscriptions: [],
            errorMessage: undefined,
            connectionInfo: undefined,
        });
        this.loadProfileWhenReady();
        this.loadCredentialsOrSubscriptionsWhenReady();
    }

    readonly setBlocked = this.updater((state, blocked: boolean) => {
        if (!state.user) {
            return state;
        }
        return {
            ...state,
            user: {
                ...state.user,
                blocked: blocked,
            },
        };
    });

    readonly setProfileUUID = this.updater((state, profileUuid: string | undefined) => ({ ...state, profileUuid }));
    readonly setAuth0User = this.updater((state, auth0User: UserProfileResponseAuth0 | undefined) => ({
        ...state,
        auth0User,
    }));
    readonly setUser = this.updater((state, user: User | undefined) => ({ ...state, user }));
    readonly setErrorMessage = this.updater((state, errorMessage: string | undefined) => ({ ...state, errorMessage }));
    readonly setHawkCredentials = this.updater((state, hawkCredentials: HawkCredential[]) => ({
        ...state,
        hawkCredentials,
    }));

    readonly addHawkCredential = this.updater((state, hawkCredential: HawkCredential) => ({
        ...state,
        hawkCredentials: [...state.hawkCredentials, hawkCredential],
    }));

    readonly setPermissions = this.updater((state, permissions: Permission[]) => ({
        ...state,
        permissions,
    }));

    readonly setNotificationSubscriptions = this.updater((state, notificationSubscriptions: string[]) => ({
        ...state,
        notificationSubscriptions,
    }));

    readonly setConnectionInfo = this.updater(
        (state, connectionInfo: AccountConfigurationAuth0Minimal | undefined) => ({
            ...state,
            connectionInfo,
        }),
    );

    readonly profileUuid$ = this.select((state) => state.profileUuid);
    readonly auth0User$ = this.select((state) => state.auth0User);
    readonly user$ = this.select((state) => state.user);
    readonly errorMessage$ = this.select((state) => state.errorMessage);
    readonly hawkCredentials$ = this.select((state) => state.hawkCredentials);
    readonly permissions$ = this.select((state) => state.permissions);
    readonly notificationSubscriptions$ = this.select((state) => state.notificationSubscriptions);
    readonly connectionInfo$ = this.select((state) => state.connectionInfo);

    readonly serviceRequestor$ = this.select(this.permissions$, (permissions) => {
        const exist = permissions.find((permission) => permission.roleName === PrimaryRole.SERVICE_REQUESTOR);
        return !!exist;
    });

    pagePermissions(
        appUser: AppUser | null | undefined,
        profileUser: User | undefined,
        serviceRequestor: boolean,
    ): PagePermissions {
        const pagePermissions: PagePermissions = {
            apiKeysView: false,
            apiKeysManage: false,
            block: false,
            editName: false,
            mfaReset: false,
            reassignNotificationEmail: false,
            resetPassword: false,
            rolesView: false,
            rolesAdd: false,
            subscriptionsView: false,
            subscriptionsManage: false,
        };
        if (appUser && profileUser) {
            pagePermissions.apiKeysView =
                appUser.id === profileUser.id ||
                appUser.systemRoles.admin ||
                appUser.systemRoles.auditor ||
                ((appUser.accountAnyRoles.admin || appUser.accountAnyRoles.auditor) && serviceRequestor);
            pagePermissions.apiKeysManage =
                appUser.systemRoles.admin ||
                appUser.id === profileUser.id ||
                (appUser.accountAnyRoles.admin && serviceRequestor);
            pagePermissions.block = AppUserUtility.canBlockUser(appUser, profileUser);
            pagePermissions.editName = appUser.systemRoles.admin || appUser.id === profileUser.id;
            pagePermissions.mfaReset =
                (appUser.systemRoles.admin || appUser.accountAnyRoles.admin) && !serviceRequestor;
            pagePermissions.reassignNotificationEmail =
                appUser.id !== profileUser.id && (appUser.systemRoles.admin || appUser.accountAnyRoles.admin);
            pagePermissions.resetPassword = AppUserUtility.canResetPasswordForUser(
                appUser,
                profileUser,
                serviceRequestor,
            );
            pagePermissions.rolesView =
                appUser.systemRoles.admin ||
                appUser.systemRoles.auditor ||
                appUser.accountAnyRoles.admin ||
                appUser.accountAnyRoles.auditor ||
                appUser.organizationAnyRoles.admin ||
                appUser.organizationAnyRoles.auditor;
            pagePermissions.rolesAdd =
                appUser.id !== profileUser.id && (appUser.systemRoles.admin || appUser.accountAnyRoles.admin);
            pagePermissions.subscriptionsView =
                appUser.systemRoles.admin || appUser.systemRoles.auditor || appUser.id === profileUser.id;
            pagePermissions.subscriptionsManage = appUser.systemRoles.admin || appUser.id === profileUser.id;
        }
        return pagePermissions;
    }

    readonly pagePermissions$: Observable<PagePermissions> = this.select(
        this.globalStore.select(AppUserSelectors.user),
        this.user$,
        this.serviceRequestor$,
        (appUser, profileUser, serviceRequestor) => {
            return this.pagePermissions(appUser, profileUser, serviceRequestor);
        },
    );

    canRemoveRole(accountId: string): Observable<boolean> {
        return this.select(this.globalStore.select(AppUserSelectors.user), this.user$, (appUser, profileUser) => {
            if (!appUser || appUser.id === profileUser?.id) {
                return false;
            }
            const accountRoles: FlattenedRoles | undefined =
                accountId in appUser.accountRoles ? appUser.accountRoles[accountId] : undefined;
            return appUser.systemRoles.admin || !!accountRoles?.admin;
        });
    }

    readonly viewModel$: Observable<ViewModel> = this.select(
        this.auth0User$,
        this.user$,
        this.pagePermissions$,
        this.hawkCredentials$,
        this.errorMessage$,
        this.connectionInfo$,
        (auth0User, user, pagePermissions, hawkCredentials, errorMessage, connectionInfo) => ({
            auth0User,
            user,
            pagePermissions,
            errorMessage,
            connectionInfo,
            allowMoreCredentials: hawkCredentials.length < this.totalAllowedCredentials,
        }),
    );

    readonly removePermission = this.effect((permission$: Observable<Permission>) => {
        return permission$.pipe(
            tap({
                next: (permission) => {
                    const dialogResult: Observable<boolean> = this.dialogsService.confirm(
                        `You are about to remove the ${permission.roleName} role from ${permission.accountName} account`,
                        "Remove This Role",
                        "Remove Role",
                    );

                    combineLatest([this.profileUuid$, dialogResult])
                        .pipe(first())
                        .subscribe(([userUuid, dialogResult]) => {
                            if (!dialogResult || !userUuid) {
                                return;
                            }
                            this.rolesService
                                .rolesUserIdPost(userUuid, {
                                    accountId: permission.accountId ?? undefined,
                                    organizationId: permission.organizationId ?? undefined,
                                    role: permission.roleName,
                                })
                                .subscribe((result) => {
                                    if (result.result === "Success") {
                                        this.setPermissionsFromRoles(
                                            result.roles as Record<
                                                string,
                                                SystemRoles | AccountRoles[] | OrganizationRoles[]
                                            >,
                                        );
                                    }
                                });
                        });
                },
            }),
        );
    });

    setPermissionsFromRoles(
        roles: Record<string, SystemRoles | AccountRoles[] | OrganizationRoles[]> | undefined,
    ): void {
        const permissions: Permission[] = [];

        if (!roles) {
            return;
        }

        for (const roleName of [PrimaryRole.SYSTEM_ADMIN, PrimaryRole.SYSTEM_AUDITOR]) {
            const systemRole: SystemRoles | undefined = roles[roleName] as SystemRoles;
            if (systemRole) {
                permissions.push({
                    accountName: systemRole.systemName,
                    roleName,
                });
            }
        }
        for (const roleName of [PrimaryRole.ACCOUNT_ADMIN, PrimaryRole.ACCOUNT_AUDITOR, PrimaryRole.DOMAIN_VALIDATOR]) {
            const accountRoles: AccountRoles[] | undefined = roles[roleName] as AccountRoles[];
            if (accountRoles) {
                for (const accountRole of accountRoles) {
                    permissions.push({
                        ...accountRole,
                        roleName,
                    });
                }
            }
        }
        for (const roleName of [
            PrimaryRole.ORGANIZATION_ADMIN,
            PrimaryRole.ORGANIZATION_AUDITOR,
            PrimaryRole.REQUESTOR,
            PrimaryRole.SERVICE_REQUESTOR,
        ]) {
            const orgRoles: OrganizationRoles[] | undefined = roles[roleName] as OrganizationRoles[];
            if (orgRoles) {
                for (const orgRole of orgRoles) {
                    permissions.push({
                        ...orgRole,
                        roleName,
                    });
                }
            }
        }
        this.setPermissions(permissions);
    }

    setConnectionInfoBySlug(slug: string): void {
        this.cachedDataService
            .getConnectionInfoForSlug(slug)
            .pipe(take(1))
            .subscribe((connection) => {
                this.setConnectionInfo(connection);
            });
    }
    loadUserFromProfileUUID(profileUuid: string): void {
        this.usersService.usersIdGet(profileUuid).subscribe(
            (result) => {
                if (!result.details) {
                    return;
                }
                this.setAuth0User(result.auth0);
                this.setUser(result.details);

                this.setConnectionInfoBySlug(result.details.authSource);

                if (result.roles) {
                    this.setPermissionsFromRoles(
                        result.roles as Record<string, SystemRoles | AccountRoles[] | OrganizationRoles[]>,
                    );
                }
            },
            (error) => {
                if (error.error?.error) {
                    this.setErrorMessage(error.error.error);
                } else {
                    this.setErrorMessage("The profile could not be loaded");
                }
            },
        );
    }

    loadedCredentials = false;
    loadedNotificationSubscriptions = false;

    loadHawkCredentialsIfPermitted(profileUuid: string, canView: boolean): void {
        if (!canView || this.loadedCredentials) {
            return;
        }
        this.loadedCredentials = true;
        this.setHawkCredentials(this.usersService.usersHawkUserIdGet(profileUuid));
    }

    loadNotificationSubscriptionsIfPermitted(profileUuid: string, canView: boolean): void {
        if (!canView || this.loadedNotificationSubscriptions) {
            return;
        }
        this.loadedNotificationSubscriptions = true;
        this.setNotificationSubscriptions(this.usersService.usersIdSubscriptionsGet(profileUuid));
    }

    loadProfileWhenReady(): void {
        combineLatest([this.globalStore.select(AppUserSelectors.user), this.profileUuid$]).subscribe(
            ([appUser, profileUuid]) => {
                if (profileUuid && appUser) {
                    this.loadUserFromProfileUUID(profileUuid);
                }
            },
        );
    }

    loadCredentialsOrSubscriptionsWhenReady(): void {
        combineLatest([this.profileUuid$, this.pagePermissions$]).subscribe(([profileUuid, permissions]) => {
            if (!profileUuid) {
                return;
            }
            this.loadHawkCredentialsIfPermitted(profileUuid, permissions.apiKeysView);
            this.loadNotificationSubscriptionsIfPermitted(profileUuid, permissions.subscriptionsView);
        });
    }
}
