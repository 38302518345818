<ng-container *ngrxLet="viewStore.vm$; let vm">
    <h2 mat-dialog-title>{{ vm.title }}</h2>
    <mat-dialog-content>
        <ng-container *ngIf="vm.mode === ApiKeyModes.NEW">
            <p>The details for the generated API key are listed below.</p>
            <p class="pb-4">
                This is the only time you will be able to view the key value, so please copy it before closing this
                window.
            </p>
        </ng-container>
        <ng-container *ngIf="vm.mode === ApiKeyModes.ROLL">
            <p class="pb-4">
                Rolling this API Key will cause it to stop working immediately and the new key will have to be used.
            </p>
        </ng-container>
        <ng-container *ngIf="vm.mode === ApiKeyModes.DELETE">
            <p>Deleting this API Key will cause it to stop working immediately.</p>
            <p class="pb-4">Once you delete this key it cannot be used again.</p>
        </ng-container>

        <div *ngIf="vm.showID" class="flex flex-row items-center place-content-between gap-3">
            <mat-form-field class="grow">
                <mat-label>ID</mat-label>
                <input matInput [formControl]="form.controls.id" />
            </mat-form-field>
            <copy-to-clipboard
                [contentToCopy]="form.controls.id.value"
                toolTipText="Copy ID"
                successMessage="ID copied!"
            ></copy-to-clipboard>
        </div>
        <div *ngIf="vm.showKey" class="flex flex-row items-center place-content-between gap-3">
            <mat-form-field class="grow">
                <mat-label>Key</mat-label>
                <input matInput [formControl]="form.controls.key" />
            </mat-form-field>
            <copy-to-clipboard
                [contentToCopy]="form.controls.key.value"
                toolTipText="Copy Key"
                successMessage="Key copied!"
            ></copy-to-clipboard>
        </div>
        <div *ngIf="vm.showComments">
            <mat-form-field>
                <mat-label>Comments</mat-label>
                <input matInput [formControl]="form.controls.comments" />
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button
            mat-flat-button
            color="warn"
            *ngIf="vm.showRemoveButton"
            (click)="viewStore.setMode(ApiKeyModes.DELETE)"
        >
            Remove
        </button>
        <button mat-flat-button color="caution" *ngIf="vm.showRollButton" (click)="viewStore.setMode(ApiKeyModes.ROLL)">
            Roll
        </button>
        <span class="flex flex-auto"></span>
        <button mat-button color="primary" [mat-dialog-close] *ngIf="vm.showCancelButton">Cancel</button>
        <button mat-button color="primary" [mat-dialog-close] *ngIf="vm.showCloseButton">Close</button>
        <button mat-flat-button color="primary" *ngIf="vm.showSaveButton" (click)="save()">Save</button>
        <button mat-flat-button color="primary" *ngIf="vm.showRollConfirmButton" (click)="roll()">Roll</button>
        <button mat-flat-button color="primary" *ngIf="vm.showRemoveConfirmButton" (click)="remove()">Confirm</button>
    </mat-dialog-actions>
</ng-container>
