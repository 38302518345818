export enum PrimaryRole {
    SYSTEM_ADMIN = "System Admin",
    SYSTEM_AUDITOR = "System Auditor",
    ACCOUNT_ADMIN = "Account Admin",
    ACCOUNT_AUDITOR = "Account Auditor",
    ORGANIZATION_ADMIN = "Organization Admin",
    ORGANIZATION_AUDITOR = "Organization Auditor",
    REQUESTOR = "Requestor",
    DOMAIN_VALIDATOR = "Domain Validator",
    UNKNOWN = "Unknown",
    SERVICE_REQUESTOR = "Service Requestor",
}
